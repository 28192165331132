h1,h2,h3,h4,h5,h6,p,span{
    font-family: "IBM Plex Sans" ,"Roboto", "Helvetica", "Arial", sans-serif;
}
.chat-history {
    margin-left: 24px;
    background: #F8F6F1 !important;
    border-radius: 12px 12px 0 0;
    padding: 150px 24px;
    height: calc(100vh - 24px);
    display: flex;
    justify-content: center;
    position: relative;
}

.chat-newopen {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin: 0 auto;
}

.chat-newopen .new-chat {
    margin-top: 15px;
}

.chat-newopen .header-main {
    font-weight: 500;
    font-size: 32px;
    color: #1C2324;
    margin: 0;
    line-height: 1.3;
    font-family: "IBM Plex Sans" ,"Roboto", "Helvetica", "Arial", sans-serif;
}

.chat-newopen .header-message {
    font-weight: 600;
    font-size: 40px;
    color: #1C2324;
    line-height: 1.3;
    font-family: "IBM Plex Sans","Roboto", "Helvetica", "Arial", sans-serif;
    margin: 0;
}

.username {
    height: 120px;
    width: 120px;
    border-radius: 100%;
    background: #108B88;
    font-weight: 600;
    font-size: 40px;
    color: #fff;
    text-align: center;
    line-height: 120px;
}

.number {
    background: #DF5200;
    min-width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    border-radius: 100%;
    display: flex;
}

.chat-textarea-wrapper .number button {
    background: none;
    border: 0;
    color: #fff;
}

.chat-history.chat-start {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.search-usserstart {
    padding: 24px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    gap: 16px;
    background: #F8F6F1;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    border-radius: 12px 12px 0 0;
    position: sticky;
    top: 0;
    z-index: 1111;
}

.search-usserstart .user-symbol {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    flex-grow: 1;
}

.search-usserstart .user-symbol img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.search-usserstart .user-symbol h4 {
    margin: 0 0 5px 0;
    font-size: 24px;
    font-weight: 500;
}

.search-usserstart .user-symbol p {
    margin: 0;
    font-size: 16px;
    color: #1C2324;
    opacity: 0.72;
    line-height: 24px;
}

.group-search {
    align-items: center;
    display: flex;
    opacity: 0.32;
}

.group-search svg {
    font-size: 32px;
    cursor: pointer;
}

#message-add {
    padding: 0px;
    overflow: auto;
    flex: 1 1 0;
}

.userchat>ul {
    overflow: auto;
    height: 100%;
    padding: 24px;
    margin: 0;
}

.day-label {
    text-align: center;
    margin-bottom: 20px;
}

.day-label span {
    display: inline-block;
    background: #F0EBE0;
    border-radius: 8px;
    padding: 8px 16px;
    color: #1C2324A3;
    font-size: 14px;
    line-height: 1.3;
}

.userchat-ul {
    height: auto;
    overflow: auto;
}

.userchat-ul ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.userchat-ul::-webkit-scrollbar {
    display: none;
}


.userchat-ul ul li {
    display: block;
}

.content-show {
    padding: 16px;
    background: #F0EBE0;
    border-radius: 0 5px 5px 5px;
    max-width: 460px;
    position: relative;
    margin-bottom: 24px;
    margin-left: 24px;
    width: fit-content;
    min-width: 100px;
    word-wrap: break-word;
}

.content-show.receiver::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-top: 10px solid #F0EBE0;
    position: absolute;
    top: 0;
    left: -10px;
}

.content-show h6 {
    margin: 0 0 5px 0;
    font-weight: 500;
    font-size: 14px;
    color: #108B88;
}

.content-show p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: #1C2324;
    opacity: 0.72;
    line-height: 1.5;
}

.content-show .chat-image{
    object-fit:contain;
    max-height:400px;
    max-width:300px
}

.chat-attachment  .chat-image{
    object-fit:contain;
    max-height:400px;
    max-width:300px
}
.content-show.sender {
    margin-left: auto;
    background: #DFE8C4;
    border-radius: 5px 0 5px 5px;
    margin-right: 1.5rem;
}

.content-show.sender::before {
    content: "";
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-top: 10px solid #DFE8C4;
    position: absolute;
    top: 0;
    right: -10px;
}

.content-show.sender h6 {
    color: #EB8F06;
}

.content-show .time-msg {
    font-size: 13px;
    color: #1C2324;
    opacity: 0.72;
    text-align: right;
}

.chat-textarea-wrapper {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    padding: 24px;
    position: relative;
    /* gap: 16px; */
}

.attachment_form {
    background: #00000010;
}

.chat-attachment {
    margin: 0 24px;
    border-bottom: 2px solid lightgray;
    padding: 24px 0;
}

.chat-textarea {
    width: 100%;
    background: #EEEDEC;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    gap: 9px;
    position: relative;
}

.chat-textarea input[type=text] {
    padding: 0;
    line-height: 24px;
    height: auto;
}

.chat-textarea .attach {
    padding: 0;
    min-width: unset;
}

/* .chat-textarea.chat-textarea-flag { */
/* border-radius: 0 0 40px 40px; */
/* } */

.chat-textarea-wrapper .number {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emoji {
    cursor: pointer;
    opacity: 0.48;
}

.attach {
    opacity: 0.48;
}

.chat-textarea form {
    margin: 0;
    width: 100%;
}

.chat-textarea form input {
    padding: 0;
    border-radius: 0;
    border: 0;
    width: 100%;
    background: transparent;
}


/* 27-02-2023 */
.chat-wrapper {
    background: #F8F6F1;
    margin-left: 24px;
}

.profile-wrapper {
    background: #F8F6F1;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    margin-bottom: 24px;
    text-align: center;
    position: relative;
    padding: 48px;
}

.close-profile-button {
    position: absolute;
    right: 33px;
    top: 33px;
}

.close-profile-button button {
    padding: 0;
    min-width: auto;
}

.close-profile-button button svg {
    width: 30px;
    height: 30px;
    fill: #1C2324;
    opacity: .32;
}

.profile-logo {
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    font-size: 32px;
    vertical-align: middle;
    position: relative;
    text-align: center;
}

.profile-logo.heading {
    height: 64px;
    width: 64px;
    font-size: 30px;
    line-height: 64px;
}

.profile-logo.heading h4,
.profile-logo.image h4 {
    background-color: #108B88;
    border-radius: 50%;
    margin: 0;
}

.profile-logo.image {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.profile-logo .profile-camera {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 28px;
    background: #F0EBE0;
    color: #EB8F06;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile-logo .profile-camera svg {
    width: 16px;
}

.profile-camera input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.profile-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 4px;
}

.profile-title h2 {
    font-size: 24px;
    color: #1C2324;
    font-weight: 500;
    display: inline-block;
}

.profile-title svg {
    width: 18px;
    height: 18px;
    fill: #1C2324;
    opacity: 0.32;
}

.profile-title-wrapper .profile-logo img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-title-wrapper p {
    margin-top: 0;
    opacity: .72;
    color: #1C2324;
}

.profile-image-media {
    background: #F0EBE0;
    padding: 16px;
    margin-bottom: 24px;
}

.profile-image-title-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 12px;
    color: #1c2324a3;
    padding: 5px;
}

.profile-image-count svg {
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.profile-images {
    display: flex;
    padding: 5px 0;
    overflow-x: auto;
}

.profile-images .profile-image-wrapper {
    padding: 0 6px;
    flex: 0 0 88px;
    max-width: 88px;
    /* width: 88px; */
    /* height: 88px; */
    aspect-ratio: 1;
}

.profile-images .profile-image-wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.profile-members {
    background: #F0EBE0;
    padding: 16px;
}

.profile-members-count p {
    margin-top: 2px;
    color: #1C2324;
    opacity: 0.64;
    font-weight: 500;
}

.profile-member {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;
}

.profile-member-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #EB8F06; */
    border-radius: 50%;
    color: #fff;
    margin-right: 16px;
}

.profile-member:nth-child(3n+1) .profile-member-title-wrapper .profile-member-icon {
    background-color: #EB8F06;
}

.profile-member:nth-child(3n+2) .profile-member-title-wrapper .profile-member-icon {
    background-color: #108B88;
}

.profile-member:nth-child(3n+3) .profile-member-title-wrapper .profile-member-icon {
    background-color: #6A7D31;
}

.profile-member-icon h6 {
    font-size: 16px;
    font-weight: 500;
}

.profile-member-title-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.profile-member-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
}

.profile-member-admin p {
    margin: 0;
    font-style: italic;
    color: #006A77;
}

.profile-member-remove button {
    background: transparent;
    border: 0;
    color: #B3261E;
    font-style: italic;
    margin-left: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.search-profile .MuiFormControl-root {
    width: 100%;
}

.search-profile {
    padding: 40px 30px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
}

.search-profile .MuiInput-underline::before {
    display: none;
}

.search-profile .MuiInputBase-input {
    border-bottom: 1px solid #1C232426;
}

.search-profile svg {
    color: #1C23246E;
}

.search-profile .MuiButton-text {
    border-radius: 0;
    padding: 0;
    background: transparent;
    min-width: 48px;
    color: #1C23246E;
}

/* ----------- signin_user_details ------------- */

.chat-history .signin_user_detail {
    position: absolute;
    bottom: 20px;
    text-align: center;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
}

.signin_user_detail .signin_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1C2324;
    opacity: 0.72;
}

.signin_user_detail .signin_user {
    font-style: italic;
    font-weight: 500;
}



/* -------- send voice audio -------- */
.voice_record_container {
    padding: 24px;
    border-radius: 20px 20px 0 0;
    background: #EEEDEC;
    display: flex;
    flex-direction: column;
}

.voice_record_waveframe {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    background: #DED9D3;
    border-radius: 8px;
    padding: 8px 16px;
    align-items: center;
}

.wave-frame-svg {
    width: 100%;
}

.wave-frame-svg img {
    object-fit: cover;
    width: 100%;
}

.pause_waveform {
    background: transparent;
}

.pause_waveform .waveframe_time_count {
    order: -1;
}

.voice_record_waveframe button {
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
}

.voice_record_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
}

.voice_record_buttons span {
    cursor: pointer;
}

.voice_record_buttons span button {
    cursor: pointer;
    background: #DF5200;
    min-width: 48px;
    height: 48px;
    line-height: 48px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
}

.waveframe_time_count {
    font-weight: 600;
}

.record_voice {
    display: flex;
    align-items: center;
}

.record_voice .waveframe_time_count {
    padding-right: 9px;
}

.add-search-chat .participant-auto {
    background: #F8F6F1;
    font-size: 1rem;
    width: 100% !important;
    border: 1px solid #F0EBE0;
    border-radius: 28px !important;
    min-height: 48px;
    display: flex;
}

.add-search-chat .search-text>div::before {
    border: 0;
    display: none;
}

.add-search-chat .search-text>div::after {
    border: 0;
    display: none;
}

.add-search-chat .search-text>div {
    border: 0;
    border-radius: 0;
    font-size: 15px;
}

.add-search-chat .search-text {
    background: #FCFAF7;
    border: 0px solid #FCFAF7;
    border-radius: 28px !important;
    outline: none !important;
    padding: 8px 20px !important;
    line-height: 1;
}

.add-search-chat {
    margin-bottom: 18px;
    display: flex;
}

.add-search-chat .search-text>div>div:nth-child(3n+1) .autocompleteAvatart {
    background-color: #6A7D31;
}

.add-search-chat .search-text>div>div:nth-child(3n+2) .autocompleteAvatart {
    background-color: #108B88;
}

.add-search-chat .search-text>div>div:nth-child(3n+3) .autocompleteAvatart {
    background-color: #EB8F06;
}

.group-name h4 {
    color: #1C2324;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
}

/* Styling for the attachment options container */
.attachment-container {
    border-radius: 12px;
    box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    bottom: 50px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    z-index: 999;
    /* width: 168px;
    height: 212px; */
    width: 233px;
    height: 212px;
}

.attachment-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px;
    margin-top: 40px;
    /* width: 168px;
    height: 212px; */
    width: 233px;
    height: 155px;
  }
  
.icon-container {
    text-align: center; /* Center the content horizontally */
}

.icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-top: 5px;
}

.doc-icon {
    width: 100px;
    height: 100px;
    background-color: #006A77;
}

.icon-video-background {
    width: 36px;
    height: 36px;
    background-color: #EB8F06;
    border-radius: 50%;
  }
  
.icon-audio-background {
    width: 36px;
    height: 36px;
    background-color: #B3261E;
    border-radius: 50%;
}

.icon-document-background {
    width: 36px;
    height: 36px;
    background-color: #108B88;
    border-radius: 50%;
}

.icon-camera-background {
    width: 36px;
    height: 36px;
    background-color: #297539;
    border-radius: 50%;
}

.icon-image-background {
    width: 36px;
    height: 36px;
    background-color: #9B824B;
    border-radius: 50%;
}

.icon-label {
    opacity: 0.64;
    color: #1C2324;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.react-player {
    max-width: 334px;
    max-height: 226px;
}

audio {
    width: 295px;
}

.media-message {
    padding: 16px;
    background: transparent;
    border-radius: 0 5px 5px 5px;
    max-width: 460px;
    position: relative;
    margin-bottom: 24px;
    margin-left: 10px;
    float: right;
}

.MuiButtonBase-root > .MuiIconButton-root > .close_btn {
    opacity: 0.48;
}

@media (max-width: 767px) {
    .profile-logo.heading {
        width: 50px;
        height: 50px;
    }
    .search-usserstart .user-symbol img {
        width: 50px;
        height: 50px;
    }
    .search-usserstart {
        padding: 24px 10px;
    }
    .chat-welcome-screen{
        display: none !important;
    }
    .content-show{
        max-width: 275px;
    }
    .content-show .chat-image{
        max-width:240px
    }
    .chat-attachment  .chat-image{
        max-width:240px
    }
    .chat-history {
        margin: 0 !important;
    }
    audio {
        width: 100%;
    }

}