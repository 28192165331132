
.chat_container input[id^="village-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.chat_container input[id^="village-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.chat_container input[id^="district-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.chat_container input[id^="district-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.chat_container input[id^="taluka-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.chat_container input[id^="taluka-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.chat_container input[id^="state-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}



.chat_container input[id^="state-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.chat_container input[id^="fieldExective-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
}

.chat_container input[id^="fieldExective-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.chat_container input[id^="farmer-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.chat_container input[id^="farmer-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

.chat_container input[id^="group-textfield"]::placeholder {
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 16px !important;
    opacity: 0.32 !important;
    color: #1C2324 !important;
}

.chat_container input[id^="group-textfield"] {
    padding: 4px 0px !important;
    background: #FFFFFF !important;
    padding-top: 0.5px !important;
}

.chat_container .PrivateSwitchBase-input-10 {
    background-color: #888 !important;
}

.chat_container .filterPopover .MuiPopover-paper {
    border-radius: 12px !important;
    overflow: initial;
}

.chat_container .centerContentTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chat_container .tableGirdDiv .MuiDataGrid-root {
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
}

.chat_container .tableSearchInputfilter>.MuiInputAdornment-positionStart {
    margin: 0 12px 0 15px !important;
}

.chat_container .MuiMenu-paper .MuiListItem-root.MuiListItem-button.Mui-selected {
    background: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 1 !important;
}

.chat_container .MuiMenu-paper .MuiListItem-root.MuiListItem-button {
    background: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #1C2324 !important;
    opacity: 0.72 !important;
}

.chat_container input[id^="searchTableId"]::placeholder {
    font-style: normal !important;
    font-weight: 450 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1C2324 !important;
    opacity: 0.48 !important;
}

.chat_container .MuiAccordionSummary-root {
    padding: 0px 12px !important;
}

.chat_container .MuiAccordionSummary-expandIcon {
    padding: 0px !important;
    margin-right: 0px !important;
}


.chat_container .userSelectionMenu .MuiSelect-icon {
    display: none !important;
}

.chat_container .userSelectionMenu .MuiSelect-root {
    /* background-image: url('./../assets/ArrowDown.png'); */
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.chat_container .userSelectionMenu .MuiSelect-root~.MuiSelect-iconOpen {
    /* background-image: url('./../assets/ArrowUp.png'); */
}

.chat_container .userSelectionMenu .MuiSelect-select.MuiSelect-select {
    padding-right: 30px !important;
}

.checkbox-container>.MuiFormControlLabel-root{
    transform: translateX(0px) !important;
    display: flex;
    gap: 0px !important;
    grid-gap: 0px !important;
}
.chat_container .checkboxImgCustom {
    height: 24px !important;
    width: 24px !important;
}

.chat_container .selectInputCustom .MuiSelect-icon {
    display: none !important;
}

.chat_container .selectInputCustom .MuiSelect-root {
    /* background-image: url('./../assets/ArrowDown.png'); */
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
}

.chat_container .fb-500 {
    font-weight: 500 !important;
}

.chat_container .opcity-48 {
    opacity: 0.48 !important;
    color: #1C2324 !important;
}

.chat_container .state-field-class>div,
.chat_container .district-field-class>div,
.chat_container .taluka-field-class>div,
.chat_container .village-field-class>div,
.chat_container .fieldExecutive-field-class>div,
.group-field-class>div,
.farmer-field-class>div
{
    background-color: #FFFFFF !important;
    border-radius: 6px !important;
}

.chat_container .state-field-class>div:focus,
.chat_container .district-field-class>div:focus,
.chat_container .taluka-field-class>div:focus,
.chat_container .village-field-class>div:focus,
.chat_container .fieldExecutive-field-class>div:focus,
.group-field-class>div:focus,
.farmer-field-class>div:focus
{
    border: 2px solid #F0EBE0 !important;
    border-radius: 6px !important;
}

.chat_container .state-field-class>div:hover,
.chat_container .district-field-class>div:hover,
.chat_container .taluka-field-class>div:hover,
.chat_container .village-field-class>div:hover,
.chat_container .fieldExecutive-field-class>div:hover,
.group-field-class>div:hover,
.farmer-field-class>div:hover
{
    border-radius: 6px !important;
}

/* Latest CSS end */
.chat_container #outlined-multiline-static-1 {
    display: none;
}

.chat_container #image-textfield-id {
    width: 100%;
    height: 200px;
    background-color: #FCFAF7 !important;
    border: 1px solid #d6d7d0;
    border-radius: 12px;
}

.chat_container #image-textfield-id .MuiInputBase-root {
    padding: 0px !important;
    background-color: #FCFAF7 !important;
    border: 0px transparent !important;
}

.chat_container #image-textfield-id span {
    padding: 0px !important;
}

.chat_container #image-textfield-id .MuiOutlinedInput-root {
    border: none !important;
    border-radius: 0px !important;
}

.chat_container #image-textfield-id>.MuiFormControl-root {
    transform: translateY(79px) !important;
    width: 100%;
    display: flex;
    align-items: center;
}

.chat_container #image-textfield-id .MuiIconButton-colorPrimary {
    color: gray !important;
}

.chat_container .camera-icon-class {
    font-size: 60px !important;
}

.chat_container .ant-tabs-class .MuiTab-wrapper {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

.chat_container .sendto-textfield .MuiOutlinedInput-inputAdornedEnd {
    padding-left: 0px !important;
}

.chat_container .sendto-textfield .MuiIconButton-edgeStart {
    padding-right: 0px !important;
}

.chat_container .date-textfield-class .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
    color: #1C2324;
    opacity: 0.3;
}

.chat_container .customDatePicker svg {
    color: #1C2324;
    opacity: 0.3;
}

.chat_container .customDatePicker {
    padding-top: 0px !important
}

/* .map-class  */
.chat_container .map-class .MuiDialog-paperWidthSm
{
    max-width: 100vw;
    width: 100vw;
    height:100vh;
} 
.chat_container .map-dialog-title{
    display: flex;
    justify-content: space-between;
    padding:10px;
    align-items: center;
}
.chat_container .filter-divider-container{
    display: flex;
    justify-content: center;
}
.chat_container .filter-divider{
    width: 80%;
    height: 1px;
    background: #F0EBE0;
    border-radius: 4px;
}

.chat_container .description-news-textfield-class .MuiOutlinedInput-input{
   padding: 14px !important;
}
.customDatePicker .MuiIconButton-root{
   padding: 0px;
}
.password-textfield-class .MuiIconButton-label{
    transform: rotateX(180deg);
}
.table-select-menuitem svg{
    margin-right: 10px !important;
}
.accordion-grid .MuiPaper-root {
    box-shadow: none !important;
}
